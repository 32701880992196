import { useLanguage, useTranslate } from '/machinery/I18n'

export function OpeningHourRangeShort({ from, until, openingHours }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return <OpeningHourBase {...{ from, until, openingHours, formatDate, formatOpeningHours }} />

  function formatDate(date) {
    return date.locale(language).format('dd').toLowerCase()
  }

  function formatOpeningHours({ closed, open, close }) {
    return (
      closed ? __`opening-hours-closed` : `${open} - ${close}`
    )
  }
}

export function OpeningHourRangeLong({ from, until, openingHours }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return <OpeningHourBase {...{ from, until, openingHours, formatDate, formatOpeningHours }} />

  function formatDate(date) {
    return date.locale(language).format('dddd').toLowerCase()
  }

  function formatOpeningHours({ closed, open, close }) {
    return (
      closed ? __`opening-hours-closed` : __({ open, close })`opening-hours-opened-short`
    )
  }
}

export function OpeningHourLongToday({ date, openingHours }) {
  const { __ } = useTranslate()

  return <OpeningHourBase from={date} until={date} {...{ openingHours, formatDate, formatOpeningHours }} />

  function formatDate() {
    return null
  }

  function formatOpeningHours({ closed, open, close }) {
    return (
      closed ? __`opening-hours-closed` : __({ open, close })`opening-hours-opened-today`
    )
  }
}

function OpeningHourBase({ from, until, openingHours, formatDate, formatOpeningHours }) {

  const { __ } = useTranslate()

  return (
    <>
      <>{formatDate(from)} </>
      {!from.isSame(until) && <>{__`opening-hours-date-connector`} {formatDate(until)} </>}
      <>{formatOpeningHours(openingHours)} </>
    </>
  )
}


