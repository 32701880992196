import { v5 as uuid } from 'uuid'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { extractLinkInfo } from '/machinery/extractLinkInfo'
import { dayjs } from '/machinery/dayjs'
import { ButtonPrimary, ButtonBlack } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'
import { ImageCover }  from '/features/buildingBlocks/Image'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { BoxWithRoundedCorners } from '/features/buildingBlocks/boxWithRoundedCorners/BoxWithRoundedCorners'
import { OpeningHourLongToday } from '/features/buildingBlocks/OpeningHour'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import crossIcon from '/images/icons/cross.raw.svg'
import styles from './HeroHome.css'

export function HeroHome({ layoutClassName, links, openingHours, openingHoursLink, image, title, infoMessage }) {
  const [showInfoBar, setShowInfoBar] = React.useState(false)
  const [nijntjeActive, setNijntjeActive] = React.useState(false)
  const { ref: visibilityRef, isInViewport } = useIsInViewport()
  const id = uuid(infoMessage ?? '', uuid.URL)

  React.useEffect(
    () => {
      try {
        const dismissed = JSON.parse(sessionStorage.getItem(id) ?? 'false')
        if (!dismissed) setShowInfoBar(true)
      } catch (_) {}
    },
    [id]
  )

  React.useEffect(
    () => {
      if (!isInViewport) {
        showNijntje(false)
        setNijntjeActive(false)
      }
    },
    [isInViewport]
  )

  return (
    <BoxWithRoundedCorners>
      <header className={cx(styles.component, layoutClassName, nijntjeActive && styles.translated)} data-x='hero' ref={visibilityRef}>
        <ImageCover layoutClassName={cx(styles.image, styles.imageDesktop)} aspectRatio={16 / 9} sizes='100vw' {...{ image }} />
        <ImageCover layoutClassName={cx(styles.image, styles.imageMobile)} aspectRatio={9 / 16} sizes='100vw' {...{ image }} />
        <div className={styles.layout}>
          {openingHoursLink && (
            <OpeningHoursNotification
              link={openingHoursLink}
              layoutClassName={styles.openingHoursNotification}
              {...{ openingHours }}
            />
          )}

          <div className={styles.block} data-style-context='orange'>
            {title && <HeadingMd h={1} {...{ title }} />}
            {links && <div className={styles.links}>
              {links.map((button, i) => {
                const Button = i === 0 ? ButtonPrimary : ButtonBlack
                const { href, label } = extractLinkInfo(button)
                const target = button.openInNewTab ? '_blank' : undefined
                const rel = target ? 'noopener noreferrer' : undefined
                const dataX = target ? 'goto-internal-pages' : 'goto-external-pages'

                return (
                  <Button
                    key={i}
                    {...{ href, target, rel, dataX }}
                    layoutClassName={styles.link}
                  >
                    {label}
                  </Button>
                )
              })}
            </div>
            }
          </div>
          {infoMessage &&
          <InfoMessage layoutClassName={styles.infoMessage} onHide={handleInfoBarHide} show={showInfoBar} text={infoMessage} />
          }
        </div>
        <Kiekeboe layoutClassName={styles.animateButton}
          onClick={handleNijntje}
          {...{ nijntjeActive }}
        />
      </header>
    </BoxWithRoundedCorners>
  )

  function handleInfoBarHide() {
    setShowInfoBar(false)
    window.sessionStorage.setItem(id, JSON.stringify(true))
  }

  function showNijntje(visible) {
    document.querySelector('.Index-nijntje').classList.toggle('is-visible', visible)
    document.querySelector('.Index-nijntje-inner').classList.toggle('is-visible', visible)
    document.querySelector('.Index-nijntje-pootje').classList.toggle('is-visible', visible)
  }

  function handleNijntje() {
    setNijntjeActive(x => !x)
    showNijntje()
  }
}

function Kiekeboe({ layoutClassName, onClick, nijntjeActive }) {
  return (
    <button
      className={cx(styles.componentKiekeboe, layoutClassName)}
      aria-label={nijntjeActive ? 'hide-nijntje' : 'show-nijntje'}
      data-x='toggle-nijntje'
      {...{ onClick }}
    >
      <div className={styles.circle} />
      <div className={cx(styles.line, styles.lineOne)} />
      <div className={cx(styles.line, styles.lineTwo)} />
      <div className={cx(styles.line, styles.lineThree)} />
    </button>
  )
}

function InfoMessage({ text, show, onHide, layoutClassName }) {
  return (
    <div className={cx(styles.componentInfoMessage, layoutClassName, !show && styles.hide )}>
      <span className={styles.infoLabel}>{text}</span>
      <button
        aria-label='button-info-message'
        onClick={onHide}
        type='button'
        data-x='hide-info-message'
      >
        <Icon icon={crossIcon} />
      </button>
    </div>
  )
}


function OpeningHoursNotification({ link, openingHours, layoutClassName }) {
  const { __ } = useTranslate()
  const href = link.ref ? determineDocumentPathSync({ document: link.ref, routeMap }) : ''

  const date = dayjs()
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = openingHours
  const days = [sunday, monday, tuesday, wednesday, thursday, friday, saturday]
  const todaysOpeningHours = openingHours.exceptions?.find(e => e.date === date.format('YYYY-MM-DD')) ?? days[date.day()]

  const target = link.openInNewTab ? '_blank' : undefined
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined

  return (
    <a {...{ href, target, rel }} className={cx(styles.componentOpeningHoursNotification, layoutClassName)} data-x='link-to-opening-hours'>
      <span className={styles.openToday}><OpeningHourLongToday openingHours={todaysOpeningHours} {...{ date }} /></span>
      <span className={styles.viewAllHours}>{__`view-all-opening-hours`}</span>
    </a>
  )
}
